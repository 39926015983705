const menuToggle = document.querySelector('.menu-toggle')
const aside = document.querySelector('.aside-menu')

const toggleAsideMenu = () => {
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth

    const body = document.body
    const siteHeader = document.querySelector('.site-header')
    const navMenu = document.querySelector('.nav-menu-aside')
    const mainMenu = document.querySelector('.main-navigation')

    if (navMenu.classList.contains('mobile-visible')) {
        navMenu.classList.remove('mobile-visible')
        menuToggle.setAttribute('aria-expanded', 'false')
        body.classList.remove('body-locked')
        body.style.paddingRight = ''
        siteHeader.style.paddingRight = ''

        aside.classList.remove('aside-menu--open')
        mainMenu.classList.remove('main-menu--desktop-hidden')

        document.removeEventListener('click', handleOutsideClick)
    } else {
        navMenu.classList.add('mobile-visible')
        menuToggle.setAttribute('aria-expanded', 'true')
        body.classList.add('body-locked')
        body.style.paddingRight = `${scrollbarWidth}px`
        siteHeader.style.paddingRight = `${scrollbarWidth}px`

        aside.classList.add('aside-menu--open')
        mainMenu.classList.add('main-menu--desktop-hidden')

        document.addEventListener('click', handleOutsideClick)
    }
}

function handleOutsideClick(event) {
    if (!aside.contains(event.target) && !menuToggle.contains(event.target)) {
        toggleAsideMenu()
    }
}

const toggleMenu = () => {
    // menu-item-has-children

    menuToggle.addEventListener('click', (e) => {
        e.preventDefault()
        toggleAsideMenu()
    })

    const menuItems = document.querySelectorAll('.aside-menu .menu-item-has-children > a')

    menuItems.forEach((el) => {
        el.addEventListener('click', (e) => {
            // if we are on mobile
            if (window.matchMedia('(hover: none)').matches) {
                e.preventDefault()
            } else {

                if (el.getAttribute('href').startsWith('#')) {
                    toggleAsideMenu()

                    return
                }
                const href = new URL(el.getAttribute('href'))
                if (window.location.origin === href.origin && window.location.pathname === href.pathname) {
                    if (href.hash === '#') {
                        e.preventDefault()
                    } else {
                        toggleAsideMenu()
                    }
                }
            }
        })

        el.addEventListener('mouseover', () => {
            const parent = el.parentNode
            parent.classList.add('menu--open')
            const submenu = parent.querySelector('.sub-menu')
            submenu.classList.add('sub-menu--open')
        })

        el.parentNode.addEventListener('mouseleave', () => {
            const parent = el.parentNode
            parent.classList.remove('menu--open')
            const submenu = parent.querySelector('.sub-menu')
            submenu.classList.remove('sub-menu--open')
        })
    })

    const singleMenuItems = document.querySelectorAll('.aside-menu a:not(.menu-item-has-children > a)')

    singleMenuItems.forEach((el) => {
        el.addEventListener('click', (e) => {
            if (el.getAttribute('href').startsWith('#')) {
                toggleAsideMenu()

                return
            }
            const href = new URL(el.getAttribute('href'))
            if (window.location.origin === href.origin && window.location.pathname === href.pathname) {
                if (href.hash === '#') {
                    e.preventDefault()
                } else {
                    toggleAsideMenu()
                }
            }
        })
    })
}

export default toggleMenu
