export function lazyLoadBgImages(className = 'back-image') {
    const elements = document.querySelectorAll(`.${className}`)

    if ('IntersectionObserver' in window) {
        const observer = new IntersectionObserver(function(entries, obs) {
            entries.forEach(function(entry) {
                if (entry.isIntersecting) {
                    // Remove the specified class when the element is visible
                    entry.target.classList.remove(className)
                    // Stop observing the element
                    obs.unobserve(entry.target)
                }
            })
        })

        elements.forEach(function(element) {
            observer.observe(element)
        })
    } else {
        // Fallback for browsers without IntersectionObserver support
        elements.forEach(function(element) {
            element.classList.remove(className)
        })
    }
}
